import React from 'react';
import { useState, useEffect } from "react";
import { useFormik } from 'formik'
import { useNavigate, useParams } from 'react-router-dom';
import { usePostJobMutation, useGetSkillsQuery } from '../api/jobCreateApiSlice'
import { useLocation } from 'react-router-dom';
import { useGetSingleJobQuery } from '../../JobSinglePage/api/singleJobApiSlice'
import { useGetSingleJobForEditQuery } from '../api/jobCreateApiSlice';
import * as Yup from 'yup'
import { LgbtToast } from '../../../components/LgbtToast/LgbtToast';
import useJobUrl from '../../../common/hooks/useJobUrl';

function useJobPostForm(jobId) {
    const loc = useLocation();
    const queryParams = new URLSearchParams(loc.search);
    const employer = parseInt(queryParams.get('employer'));
    const formRef = React.useRef(null);

    const [createJob, { data: jobPostData, isJobFetching, isJobLoading, isJobError, jobError }] = usePostJobMutation();
    const { data: repostJobData, isFetching: isRepostJobFetching, isLoading: isRepostJobLoading, isError: repostJobError } = useGetSingleJobForEditQuery(jobId.jobId);
    const jobUrl = useJobUrl();
    const navigate = useNavigate();

    const [errorMessage, setErrorMessage] = useState("");

    const initialValues = {
        title: "",
        description: "",
        salary_amount: 0,
        maximum_salary: 0,
        contract_types: [],
        location_strings: [],
        salary_pack: "",
        salary_type: 0,
        salary_text: "",
        sectors: [],
        apply_link: "",
        applyLinkType: 'url',
        show_on_lgbtjobscouk: true,
        show_on_disabilityjobcouk: true,
        show_on_bmejobscouk: true,
        show_on_neurodiversityjobscouk: true,
        is_remote_job: false,
        employer: employer,
        status: 1,
        skills: [],
        expiry_date: null,
        scheduled_post_date: null,
        attached_file: [],
    }

    const validationSchema = Yup.object({
        'title': Yup.string().required('Required').max(100, 'Max 100 characters'),
        'description': Yup.string().max(65535, 'Max 65535 characters'),
        'location_strings': Yup.array(),
        'applyLinkType': Yup.string(),
        'apply_link': Yup.string()
            .when('applyLinkType', {
                is: 'url',
                then: () => Yup.string().url('Please enter a valid URL').required('URL is required'),
                otherwise: () => Yup.string().email('Please enter a valid email address').required('Email is required'),
            }),
        'contract_types': Yup.array(),
        'skills': Yup.array()
            .of(Yup.mixed().test(
                'isValidValue',
                'Invalid Skill',
                value => typeof value === 'number' || typeof value === 'string'
            )),
        'salary_pack': Yup.string().optional(),
        'salary_amount': Yup.number().optional(),
        'maximum_salary': Yup.number().optional(),
        'salary_text': Yup.string().optional().nullable(),
        'salary_type': Yup.number().optional().nullable(),
        'expiry_date': Yup.date()
            .min(new Date(), 'Expiry date must be in the future').optional().nullable(),
        'scheduled_post_date': Yup.date()
            .min(new Date(), 'Scheduled date must be in the future').optional().nullable(),
        'sectors': Yup.array(),
        'show_on_lgbtjobscouk': Yup.boolean().optional(),
        'show_on_disabilityjobcouk': Yup.boolean().optional(),
        'show_on_bmejobscouk': Yup.boolean().optional(),
        'show_on_neurodiversityjobscouk': Yup.boolean().optional(),
        'is_remote_job': Yup.boolean().optional(),
        'employer': Yup.number(),
    })

    const jobPostForm = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values, bag) => {
            if (values.expiry_date && values.scheduled_post_date) {
                const expiryDate = new Date(values.expiry_date);
                const scheduledPostDate = new Date(values.scheduled_post_date);

                if (expiryDate <= scheduledPostDate) {
                    LgbtToast({
                        message: 'The expiry date must exceed the scheduled post date.',
                        type: 'error',
                    });
                    return; 
                }
            }

            // changing the date format to ISOString
            if (values.expiry_date instanceof Date)
                values.expiry_date = values.expiry_date.toISOString();

            // changing the scheduled date format to ISOString
            if (values.scheduled_post_date instanceof Date)
                values.scheduled_post_date = values.scheduled_post_date.toISOString();

            values.salary = {
                title: values.salary_text,
                amount: values.salary_amount !== 0 ? parseFloat(values.salary_amount) || null : null,
                maximum_salary: values.maximum_salary !== 0 ? parseFloat(values.maximum_salary) || null : null,
                salary_type: values.salary_type
            }

            // // Convert attached files to Base64
            // const convertFileToBase64 = (file) => {
            //     return new Promise((resolve, reject) => {
            //         const reader = new FileReader();
            //         reader.readAsDataURL(file);
            //         reader.onload = () => resolve(reader.result);
            //         reader.onerror = error => reject(error);
            //     });
            // };

            // // Handle multiple files in attached_file (if present)
            // let filesBase64 = [];
            // if (values.attached_file && values.attached_file.length > 0) {
            //     try {
            //         filesBase64 = await Promise.all(values.attached_file.map(file => convertFileToBase64(file)));
            //     } catch (error) {
            //         console.error('File conversion to Base64 failed:', error);
            //         LgbtToast({ message: 'Error converting files', type: 'error' });
            //         return;
            //     }
            // }

            // values.attached_file = filesBase64;

            // // Create FormData to handle file upload
            // const formData = new FormData();

            // // Append form values to formData
            // for (let key in values) {
            //     if (key === 'attached_file' && values.attached_file) {
            //         values.attached_file.forEach(file => {
            //             formData.append('attached_file', file); // Attach each file
            //         });
            //     } else if (key === 'salary' && values.salary) {
            //         formData.append('salary', JSON.stringify(values.salary));
            //     } else {
            //         formData.append(key, values[key]);
            //     }
            // }

            LgbtToast({ message: 'Posting job...', type: 'info' })
            let jobData = await createJob(values);
            if (jobData.error) {
                const Error = () => (<>Failed creating the job</>)
                setErrorMessage(jobData.error.data.detail);
                LgbtToast({ message: (<Error />), type: 'error' })
                return false;
            }
            const Success = () => (<>Job posted successfully! <a target='_blank' style={{ color: "#365BB8" }} href={jobUrl(jobData.data.slug)}>View</a></>)
            LgbtToast({ message: (<Success />), type: 'success' })
            setErrorMessage('')
            bag.resetForm();
            if (jobData.data.status === 5) {
                navigate(`/job/preview/?slug=${jobData.data.slug}&timestamp=${jobData.data.timestamp}`, { replace: true })
            } else {
                navigate('/dashboard', { replace: true })
            }
        }
    })
    useEffect(() => {

        if (!repostJobError && !isRepostJobFetching && !isRepostJobLoading && repostJobData) {
            if (!formRef.current) {

                let linkType = 'url', apply_link = repostJobData.apply_link;
                if (repostJobData.apply_link && repostJobData.apply_link.includes('@')) {
                    linkType = 'email';
                    apply_link = repostJobData.apply_link.replace('mailto:', '');
                }

                jobPostForm.setValues({
                    title: repostJobData.title,
                    description: repostJobData.description,
                    contract_types: repostJobData.contract_types,
                    location_strings: repostJobData?.location?.map(location => location?.name).filter(name => name) || [],
                    salary_amount: parseFloat(repostJobData.salary?.amount) || 0,
                    maximum_salary: parseFloat(repostJobData.salary?.maximum_salary) || 0,
                    salary_type: repostJobData.salary?.salary_type || null,
                    salary_text: repostJobData.salary?.title || '',
                    sectors: repostJobData.sectors,
                    apply_link: apply_link,
                    applyLinkType: linkType, // Adjust based on your actual condition
                    status: repostJobData.status,
                    skills: repostJobData.skills || [], // Assuming skills is an array
                    show_on_lgbtjobscouk: repostJobData?.show_on_lgbtjobscouk || false,
                    show_on_disabilityjobcouk: repostJobData?.show_on_disabilityjobcouk || false,
                    show_on_bmejobscouk: repostJobData?.show_on_bmejobscouk || false,
                    is_remote_job: repostJobData?.is_remote_job || false,
                    attached_file: repostJobData?.attached_file || [],
                })
                formRef.current = true;
            }
        }
    }, [repostJobData, isJobLoading, isJobFetching, isJobError]);
    return { jobPostForm, isJobFetching, isJobLoading, isJobError, jobError, errorMessage }
}

export default useJobPostForm