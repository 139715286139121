import DatePicker from 'react-date-picker';

import FieldError from '../FieldError/FieldError';

import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';

import styles from './PostScheduleDate.module.scss';
function PostScheduleDate({ form, context }) {

    return (
        <section className={`${styles.postFields} jobTypeFieldsWrap fieldGroupWrap`}>
            <div className="row">
                <div className="fieldHead col-md-3">
                    <h2>Post Schedule Date</h2>
                </div>
                <div className="jobFields col-md-9">
                    <div className="row">
                        <div className="col-12">
                            <div className="fieldWrap">
                                <div className="labelBox">
                                    <label htmlFor="contract-type">Job Posting Visibility Until</label>
                                </div>
                                {context === "updated" ? (
                                    <DatePicker
                                        minDate={new Date()}
                                        format="y/MM/dd"
                                        calendarClassName={styles.calendar}
                                        clockClassName={styles.clock}
                                        className={styles.dateField}
                                        onChange={(e) => {
                                            form.setFieldValue('scheduled_post_date', e);
                                        }}
                                        value={form?.values?.scheduled_post_date || null} 
                                    />
                                ) : (
                                    <DatePicker
                                        format="y/MM/dd"
                                        calendarClassName={styles.calendar}
                                        clockClassName={styles.clock}
                                        className={styles.dateField}
                                        onChange={(e) => {
                                            form.setFieldValue('scheduled_post_date', e);
                                        }}
                                        value={form?.values?.scheduled_post_date || null} 
                                    />
                                )}
                                <FieldError 
                                    error={form?.errors?.scheduled_post_date} 
                                    touched={form?.touched?.scheduled_post_date} 
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}


export default PostScheduleDate


